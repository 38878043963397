import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "./Videos.css";
import { imageUrl, saroAPI } from '../constant';
import { retailerNAME } from '../constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faVolumeUp, faVolumeMute, faMinusCircle, faPlusCircle, faExpand, faCompress, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useData } from '../../Context.js';
import placeholderImage from "../../images/PlaceholderImage.webp"
//import screenfull from "screenfull"; // ✅ Import cross-platform fullscreen library
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';


const Videos = () => {
    const { data } = useData();

    const [cart, setCart] = useState([]); // State to manage the cart
    const [loadingProduct, setLoadingProduct] = useState(null); // Manage loader for a specific product
    const hardCodedToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
    const [videos, setVideos] = useState([]); // State for videos
    const videoRefs = useRef([]); // Dynamic refs for multiple videos
    const sliderRef = useRef(null); // Ref for the Slider
    const [isPlaying, setIsPlaying] = useState(true); // State to track play/pause
    const [isMuted, setIsMuted] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0); // Initialize active slide to the first video
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null); // For modal
    const [viewerId, setViewerId] = useState(null); // Add this state
    const [activeVideoId, setActiveVideoId] = useState(null); // Store active video ID
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const touchStartY = useRef(null); // ✅ Track swipe gestures
    const [isFullScreen, setIsFullScreen] = useState(false); // ✅ Track full-screen mode


    // useEffect(() => {
    //     const fetchProductsForVideos = async () => {
    //         const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";
    //         try {
    //             const response = await fetch(
    //                 `${saroAPI}/fetchVideoShowsProductDetails?retailerName=${retailerNAME}`,
    //                 {
    //                     method: "GET",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 }
    //             );

    //             if (response.ok) {
    //                 const responseData = await response.json();
    //                 // console.log("API Response for Products:", responseData);

    //                 if (responseData.products) {
    //                     const productsByVideoID = {};

    //                     responseData.products.forEach((product) => {
    //                         const videoID = product.videoShowID;

    //                         // Log for debugging
    //                         // console.log(
    //                         //     `Processing product with ID: ${product.id} and variants: `,
    //                         //     product.variants
    //                         // );

    //                         // Group products by `videoShowID`
    //                         if (!productsByVideoID[videoID]) {
    //                             productsByVideoID[videoID] = [];
    //                         }

    //                         // Avoid duplicate products (if the same product appears multiple times)
    //                         if (!productsByVideoID[videoID].some((item) => item.id === product.id)) {
    //                             // Attach the variants correctly to each product
    //                             productsByVideoID[videoID].push({
    //                                 ...product,
    //                                 variants: product.variants || [], // Default to an empty array if no variants
    //                             });
    //                             // console.log(`Added product ${product.id} to Video ID: ${videoID}`);
    //                         } else {
    //                             console.warn(
    //                                 `Duplicate product ${product.id} detected for Video ID: ${videoID}`
    //                             );
    //                         }
    //                     });

    //                     // console.log("Final Grouped Products by Video ID:", productsByVideoID);
    //                     setProducts(productsByVideoID); // Set products grouped by video ID
    //                 } else {
    //                     console.warn("No products found in API response.");
    //                     setProducts({});
    //                 }
    //             } else {
    //                 console.error("API Error:", response.statusText);
    //                 setProducts({});
    //             }
    //         } catch (error) {
    //             console.error("Error fetching products:", error.message);
    //             setProducts({});
    //         }
    //     };

    //     fetchProductsForVideos();
    // }, []);

    // useEffect(() => {
    //     const fetchProductsForCurrentVideo = async () => {
    //         if (!videos[activeVideoIndex]) return;

    //         const videoShowId = videos[activeVideoIndex].videoID;
    //         const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";

    //         try {
    //             const response = await fetch(
    //                 `${saroAPI}/fetchVideoShowProducts?videoShowId=${videoShowId}&retailerName=${retailerNAME}`,
    //                 {
    //                     method: "GET",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 }
    //             );

    //             if (response.ok) {
    //                 const responseData = await response.json();
    //                 console.log(`🛍️ Products for Video ID ${videoShowId}:`, responseData);

    //                 if (responseData.products) {
    //                     setProducts({ [videoShowId]: responseData.products });
    //                 } else {
    //                     setProducts({});
    //                 }
    //             } else {
    //                 console.error("❌ Failed to fetch products:", response.statusText);
    //                 setProducts({});
    //             }
    //         } catch (error) {
    //             console.error("❌ Error fetching products:", error.message);
    //             setProducts({});
    //         }
    //     };

    //     fetchProductsForCurrentVideo();
    // }, [activeVideoIndex]); // ✅ Runs every time the video changes

    useEffect(() => {
        const fetchProductsForCurrentVideo = async () => {
            if (!videos[activeVideoIndex]) return;

            const videoShowId = videos[activeVideoIndex].videoID; // Get current video ID

            // ✅ Avoid duplicate API calls for already fetched products
            if (products[videoShowId]) {
                //console.log(`⏩ Skipping API call, products already fetched for Video ID: ${videoShowId}`);
                return;
            }

            const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJraGF6YW5heTIiLCJpYXQiOjE3MjM0NjQyODh9.qLkB7xmH1Us_DC1-h1HwvfcxazeElPonoelFSPAXTVU";

            try {
                const response = await fetch(
                    `${saroAPI}/fetchVideoShowProducts?videoShowId=${videoShowId}&retailerName=${retailerNAME}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    const responseData = await response.json();
                    //console.log(`🛍️ Products for Video ID ${videoShowId}:`, responseData);

                    if (responseData.products) {
                        setProducts(prevProducts => ({
                            ...prevProducts,
                            [videoShowId]: responseData.products // ✅ Store in state to prevent duplicate API calls
                        }));
                    }
                } else {
                    //console.error("❌ Failed to fetch products:", response.statusText);
                }
            } catch (error) {
                //console.error("❌ Error fetching products:", error.message);
            }
        };

        fetchProductsForCurrentVideo();
    }, [activeVideoIndex, videos]); // ✅ Runs when video changes OR videos are loaded

    // ✅ New useEffect to fetch products for the first video when videos are initially loaded
    useEffect(() => {
        if (videos.length > 0) {
            setActiveVideoId(videos[0].videoID); // ✅ Set the first video as active
        }
    }, [videos]);



    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(
                    `${saroAPI}/getShopableVideos?retailerName=${retailerNAME}&pageSize=100`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${hardCodedToken}`,
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();

                    // ✅ Sort videos based on `status` (active first) and `orderBy` (ascending)
                    const sortedVideos = data.data.data
                        .filter(video => video.status !== 0) // ✅ Exclude videos where status is 0
                        .sort((a, b) => {
                            // ✅ First, sort by `status` (active first, then inactive)
                            if (a.status === "active" && b.status !== "active") return -1;
                            if (a.status !== "active" && b.status === "active") return 1;

                            // ✅ Then, sort by `orderBy` in ascending order
                            return a.orderBy - b.orderBy;
                        });

                    setVideos(sortedVideos); // ✅ Store filtered & sorted videos

                    if (sortedVideos.length > 0) {
                        setActiveVideoId(sortedVideos[0].videoID); // ✅ Set first sorted video as active
                        // console.log("Sorted Video Data:", sortedVideos);
                        // console.log("First Video URL:", sortedVideos[0].productsUrls?.[0]);
                    }

                    // ✅ Set slider to first video after sorting
                    sliderRef.current.slickGoTo(0);
                } else {
                    console.error("Failed to fetch videos:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching videos:", error);
            }
        };

        fetchVideos();
    }, []);

    const productSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one product at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    //console.log("Products received in Videos:", products); // Debugging products

    // const handleAddToCart = async (product) => {
    //     setLoadingProduct(product.id);

    //     try {
    //         // const variantId = product.variantID.split('/').pop();

    //         const response = await fetch(`${saroAPI}/addToCart`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${hardCodedToken}`,
    //             },
    //             body: JSON.stringify({
    //                 retailerName: "abibas",
    //                 liveShowId: "zzAG2cCAoUYa64RulO3Q",
    //                 productId: "7819105239087",
    //                 quantity: 1,
    //                 variantId:"00",
    //                 title: product.title,
    //                 price: product.price,
    //                 image: product.image,
    //             }),
    //         });

    //         const result = await response.json();
    //         if (response.ok) {
    //             setCart((prevCart) => {
    //                 const existingItem = prevCart.find((item) => item.id === product.id);
    //                 if (existingItem) {
    //                     return prevCart.map((item) =>
    //                         item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
    //                     );
    //                 }
    //                 return [...prevCart, { ...product, quantity: 1 }];
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Error adding product to cart:", error);
    //     } finally {
    //         setLoadingProduct(null);
    //     }
    // };

    const handleAddToCart = async (product) => {
        // console.log("🛒 Add to Cart Clicked!");
        // console.log("📌 Product:", product);
        // console.log("🎥 Video ID:", product.videoId || selectedProduct?.videoId);

        //console.log('Add to cart button pressed');

        const videoId = product.videoId || selectedProduct?.videoId;

        if (!videoId) {
            //console.error("🚨 Missing videoId!");
            alert("Error: Missing videoId!");
            return;
        }

        if (!product.id) {
            //console.error("🚨 Missing product ID!", product);
            alert("Error: Missing Product ID!");
            return;
        }

        setLoadingProduct(product.id);
        try {
            //console.log("🔍 Debug - Product ID:", product.id, "Type:", typeof product.id);

            const productId = typeof product.id === 'string' ? product.id.split('/').pop() : String(product.id);
            const selectedVariantId = product.selectedVariant || product.variants?.[0]?.id;

            if (!selectedVariantId) {
                alert("No variant selected or available.");
                return;
            }

            // ✅ Get cartId from cookies (if exists)
            let cartId = Cookies.get('cart') || '';
            if (cartId && !cartId.startsWith('gid://shopify/Cart/')) {
                cartId = `gid://shopify/Cart/${cartId}`;
            }


            const payload = {
                retailerName: retailerNAME,
                cartId: cartId,
                videoId,
                productId,
                variantId: typeof selectedVariantId === 'string'
                    ? selectedVariantId.split('/').pop()
                    : String(selectedVariantId),
                quantity: 1,
                title: product.title,
                price: product.variants?.find((v) => v.id === selectedVariantId)?.price || product.price,
                image: product.image,
            };

            // console.log("📤 Payload Being Sent:", payload);

            const response = await fetch(`${saroAPI}/addToCart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            // console.log("✅ Add to Cart API Response:", result);

            if (response.ok) {
                if (!cartId && result.shopifyCartId) {
                    Cookies.set('cart', result.shopifyCartId, { expires: 7 });
                    //console.log("🛍️ Saved new Cart ID in Cookies:", result.shopifyCartId);
                }

                setCart((prevCart) => {
                    const existingItem = prevCart.find((item) => item.id === product.id);
                    if (existingItem) {
                        return prevCart.map((item) =>
                            item.id === product.id
                                ? { ...item, quantity: item.quantity + 1, videoId }
                                : item
                        );
                    }
                    return [...prevCart, { ...product, quantity: 1, videoId }];
                });
            }
        } catch (error) {
            //console.error('❌ Error adding product to cart:', error);
        } finally {
            setLoadingProduct(null);
        }
    };

    const handleRemoveFromCart = async (product) => {
        let cartId = Cookies.get('cart');
        if (!cartId) {
            //console.warn("No cart available.");
            return;
        }
            if (cartId && !cartId.startsWith('gid://shopify/Cart/')) {
                cartId = `gid://shopify/Cart/${cartId}`;
            }

        //console.log('Removing product', product);

        setLoadingProduct(product.id); // Start loader for this product
        try {
            //const productId = typeof product.id === 'string' ? product.id.split('/').pop() : String(product.id);
            let variantId = product.selectedVariant || product.variants?.[0]?.id;

            // ✅ Extract only the numeric part of the Shopify variant ID
            variantId = variantId.split('/').pop(); 

            const response = await fetch(`${saroAPI}/removeFromCart`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify({
                    retailerName: retailerNAME,
                    cartId, // Use stored viewer ID
                    variantId,
                    quantity: 1,

                }),
            });

            if (response.ok) {
                setCart((prevCart) => {
                    const existingItem = prevCart.find((item) => item.id === product.id);
                    if (existingItem.quantity === 1) {
                        return prevCart.filter((item) => item.id !== product.id);
                    }
                    return prevCart.map((item) =>
                        item.id === product.id
                            ? { ...item, quantity: item.quantity - 1 }
                            : item
                    );
                });
            }
        } catch (error) {
            //console.error('Error removing product from cart:', error);
        } finally {
            setLoadingProduct(null); // Stop loader
        }
    };

    const handleCheckout = async (videoId) => {
        if (cart.length === 0) {
            alert("Your cart is empty!");
            return;
        }

        let cartId = Cookies.get('cart');
        if (!cartId) {  // ✅ Now checking the updated viewerId stored from Add to Cart API
            //console.error("🚨 Checkout Error: Viewer ID is missing!");
            alert("Error: Viewer ID is missing. Please add an item to the cart first.");
            return;
        }
            if (cartId && !cartId.startsWith('gid://shopify/Cart/')) {
                cartId = `gid://shopify/Cart/${cartId}`;
            }

        if (!videoId) {
            //console.error("🚨 Checkout Error: Missing videoId!");
            alert("Error: Missing videoId for checkout.");
            return;
        }

        try {
            const response = await fetch(`${saroAPI}/checkout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${hardCodedToken}`,
                },
                body: JSON.stringify({
                    retailerName: retailerNAME,
                    cartId, 
                    videoId,
                }),
            });

            const result = await response.json();
            // console.log("✅ Checkout API Response:", result);

            if (response.ok) {
                if (result.checkoutUrl) {
                    window.location.href = result.checkoutUrl;
                } else {
                    alert("Checkout failed.");
                }
            } else {
                //console.error("❌ Failed to initiate checkout:", result);
            }
        } catch (error) {
            //console.error("❌ Error during checkout:", error);
        }
    };

    const isInCart = (product) => {
        return cart.some((cartItem) => cartItem.id === product.id);
    };


    const toggleMuteUnmute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

    const togglePlayPause = (event, index) => {
        if (index !== activeVideoIndex) return; // ✅ Only allow play/pause for the active video

        const videoElement = videoRefs.current[index]; // ✅ Get the video element
        if (!videoElement) return; // ✅ Prevent errors if ref is undefined

        const clickY = event.clientY;
        const clickX = event.clientX;
        const videoRect = videoElement.getBoundingClientRect();

        const videoHeight = videoElement.clientHeight;
        const videoWidth = videoElement.clientWidth;
        const isMobile = window.innerWidth <= 768; // ✅ Check if it's mobile view

        // ✅ Define Exclusion Zones (Top 20%, Bottom 20%, Left 20%, Right 20%)
        const topThreshold = videoRect.top + videoHeight * 0.2;
        const bottomThreshold = videoRect.top + videoHeight * 0.8;
        const leftThreshold = videoRect.left + videoWidth * 0.2;
        const rightThreshold = videoRect.left + videoWidth * 0.8;

        if (isMobile) {
            // ✅ Only allow play/pause if clicked in the middle 60% (vertically & horizontally)
            if (clickY > topThreshold && clickY < bottomThreshold && clickX > leftThreshold && clickX < rightThreshold) {
                setIsPlaying((prevIsPlaying) => {
                    const newPlayingState = !prevIsPlaying;
                    newPlayingState ? videoElement.play() : videoElement.pause();
                    return newPlayingState;
                });
            }
        } else {
            // ✅ For web view, allow play/pause on the **entire video**
            setIsPlaying((prevIsPlaying) => {
                const newPlayingState = !prevIsPlaying;
                newPlayingState ? videoElement.play() : videoElement.pause();
                return newPlayingState;
            });
        }
    };



    useEffect(() => {
        if (videoRefs.current[activeVideoIndex]) {
            if (isPlaying) {
                videoRefs.current[activeVideoIndex].play();
            } else {
                videoRefs.current[activeVideoIndex].pause();
            }
        }
    }, [activeVideoIndex, isPlaying]);

    const handleShopNowClick = async (product, videoId) => {
        // console.log("🛍️ Shop Now Clicked for:", product.title);
        // console.log("🎥 Associated Video ID:", videoId);

        // ✅ Open the modal immediately (before API call)
        setSelectedProduct({
            ...product,
            variants: product.variants || [],
            selectedVariant: product.variants?.[0]?.id || null,
            videoId,
        });

        document.body.classList.add("modal-open"); // ✅ Open modal instantly

        // API Payload
        const payload = {
            retailerName: retailerNAME,
            videoShowId: videoId,
            eventType: "shopNowClick",
        };

        // ✅ API call runs asynchronously in the background
        fetch(`${saroAPI}/logShoppableEvent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${hardCodedToken}`,
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(result => console.log("✅ logShoppableEvent API Response:", result))
            .catch(error => console.error("❌ Error logging shoppable event:", error));
    };



    const closeModal = () => {
        setSelectedProduct(null); // Close modal
        document.body.classList.remove("modal-open"); // Remove class when modal closes
    };

    const CustomPrevArrow = ({ onClick }) => {
        return (
            <div
                className={`custom-prev-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}
                onClick={() => {
                    closeModal();
                    onClick();
                }}
            >
                &#10094;
            </div>
        );
    };

    const CustomNextArrow = ({ onClick }) => {
        return (
            <div
                className={`custom-next-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}
                onClick={() => {
                    closeModal();
                    onClick();
                }}
            >
                &#10095;
            </div>
        );
    };

    const NextArrow = ({ onClick }) => {
        return (
            <div className={`next-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}

                onClick={() => {
                    // closeModal();
                    onClick();
                }}
            >

                &#10095; {/* Right Arrow Icon */}
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className={`prev-arrow ${window.innerWidth <= 768 ? "show-in-mobile" : ""}`}
                onClick={() => {
                    // closeModal();
                    onClick();
                }}>
                &#10094; 
            </div>
        );
    };


    useEffect(() => {
        // console.log('🔄 Video changed, updating active video');

        // Call the API without affecting video playback
        const logVideoClickEvent = async () => {
            if (!videos[activeVideoIndex]) return;

            const payload = {
                retailerName: retailerNAME,  // Ensure retailer name is set
                videoShowId: videos[activeVideoIndex].videoID,
                eventType: "newVideoClick",
            };

            try {
                const response = await fetch(`${saroAPI}/logShoppableEvent`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${hardCodedToken}`,
                    },
                    body: JSON.stringify(payload),
                });

                const result = await response.json();
                // console.log("✅ logShoppableEvent API Response:", result);
            } catch (error) {
                // console.error("❌ Error logging video change event:", error);
            }
        };

        // Call API **without waiting** for video to play
        logVideoClickEvent();

        // Update video playback logic
        videoRefs.current.forEach((videoEl, idx) => {
            if (!videoEl) return;

            if (idx === activeVideoIndex) {
                videoEl.play();  // ✅ Start playing new video immediately
            } else {
                videoEl.pause();
                videoEl.currentTime = 0;
            }
        });
    }, [activeVideoIndex]);


    const sliderSettings = {
        dots: false,
        infinite: true, // Disable infinite scrolling
        speed: 500,
        slidesToShow: 5, // Adjust based on design needs
        slidesToScroll: 1,
        // Disable center mode to prevent showing partial slides
        // If you want to keep centerMode, set centerPadding to '0px'
        centerMode: true,
        centerPadding: '0px',
        autoplay: false,
        autoplaySpeed: 4000,
        vertical: false,
        initialSlide: 0,

        afterChange: (current) => {
            // console.log(`Current active slide: ${current}`);
            // setActiveSlide(current);
            setActiveVideoIndex(current);
            setIsPlaying(true);
            setSelectedProduct(null);
            document.body.classList.remove("modal-open"); // Ensure modal class is removed
            setShowOptions(false);

        },
        // Optional: Customize arrows if needed
        nextArrow: activeVideoIndex < videos.length - 1 ? <CustomNextArrow /> : null,
        prevArrow: activeVideoIndex > 0 ? <CustomPrevArrow /> : null,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    arrows: false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1, // Full-screen video
                    centerMode: false,
                    centerPadding: '0px',
                    arrows: true, // Remove arrows for better mobile UX
                    swipeToSlide: true, // Enable touch-based swiping
                    touchThreshold: 10, 
                    verticalSwiping: true, 
                    verticalSwiping: true,
                },
            },
        ],
    };

    const handleTouchStart = (event) => {
        if (isFullScreen) {
            touchStartY.current = event.touches[0].clientY;
            // console.log("🛑 Swipe detected – closing product modal...");
            setSelectedProduct(null);  // ✅ Close modal immediately
            document.body.classList.remove("modal-open");
        }
    };

    const handleTouchMove = (event) => {
        if (!isFullScreen || touchStartY.current === null) return;

        const touchEndY = event.touches[0].clientY;
        const deltaY = touchStartY.current - touchEndY;

        if (Math.abs(deltaY) > 50) {
            // Swipe Up → Next Video
            if (deltaY > 0 && activeVideoIndex < videos.length - 1) {
                switchToVideo(activeVideoIndex + 1);
            }
            // Swipe Down → Previous Video
            else if (deltaY < 0 && activeVideoIndex > 0) {
                switchToVideo(activeVideoIndex - 1);
            }

            touchStartY.current = null;
        }
    };

    // ✅ Function to smoothly switch videos

    const switchToVideo = (newIndex) => {
        // console.log("🎥 Switching to Video:", newIndex);

        // ✅ Close product modal first
        setSelectedProduct(null);
        document.body.classList.remove("modal-open");

        setTimeout(() => {
            setActiveVideoIndex(newIndex);
            sliderRef.current.slickGoTo(newIndex); // ✅ Now switch videos

            videoRefs.current.forEach((video, index) => {
                if (video) {
                    if (index === newIndex) {
                        video.play();
                    } else {
                        video.pause();
                        video.currentTime = 0;
                    }
                }
            });
        }, 50); // ✅ Shorter delay for smoother UX
    };

    // const handleFullscreen = () => {
    //     const videoContainer = document.querySelector(".slick-slider,.slick-initialized");

    //     if (!document.fullscreenElement) {
    //         // Enter Fullscreen Mode
    //         if (videoContainer.requestFullscreen) {
    //             videoContainer.requestFullscreen();
    //         } else if (videoContainer.mozRequestFullScreen) {
    //             videoContainer.mozRequestFullScreen();
    //         } else if (videoContainer.webkitRequestFullscreen) {
    //             videoContainer.webkitRequestFullscreen();
    //         } else if (videoContainer.msRequestFullscreen) {
    //             videoContainer.msRequestFullscreen();
    //         }
    //     } else {
    //         // Exit Fullscreen Mode
    //         if (document.exitFullscreen) {
    //             document.exitFullscreen();
    //         } else if (document.mozCancelFullScreen) {
    //             document.mozCancelFullScreen();
    //         } else if (document.webkitExitFullscreen) {
    //             document.webkitExitFullscreen();
    //         } else if (document.msExitFullscreen) {
    //             document.msExitFullscreen();
    //         }
    //     }
    // };


    // useEffect(() => {
    //     const handleFullscreenChange = () => {
    //         const isFull = !!document.fullscreenElement;
    //         setIsFullScreen(isFull);

    //         if (isFull) {
    //             document.body.classList.add("fullscreen-mode");
    //         } else {
    //             document.body.classList.remove("fullscreen-mode");
    //         }
    //     };

    //     document.addEventListener("fullscreenchange", handleFullscreenChange);

    //     return () => {
    //         document.removeEventListener("fullscreenchange", handleFullscreenChange);
    //         document.body.classList.remove("fullscreen-mode"); // Ensuring cleanup on unmount
    //     };
    // }, []);

    // ✅ LISTEN FOR FULLSCREEN EXIT TO RESET VIDEO BEHAVIOR
    // useEffect(() => {
    //     const handleFullscreenChange = () => {
    //         if (!document.fullscreenElement) {
    //             setIsFullScreen(false);
    //             document.body.classList.remove("mobile-fullscreen-mode");

    //             // ✅ Restore previous state
    //             if (videoRefs.current[activeVideoIndex]) {
    //                 videoRefs.current[activeVideoIndex].play(); // Resume playing after exiting fullscreen
    //             }
    //         }
    //     };

    //     document.addEventListener("fullscreenchange", handleFullscreenChange);
    //     return () => {
    //         document.removeEventListener("fullscreenchange", handleFullscreenChange);
    //     };
    // }, [activeVideoIndex]);

    const handleFullscreen = () => {
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    
        if (isIOS) {
            document.body.classList.add("ios-fullscreen");
            document.documentElement.classList.add("ios-fullscreen");
    
            // ✅ Prevent Scrolling and Keep the Video Visible
            setTimeout(() => {
                window.scrollTo(0, 0); // Keep the screen fixed at top
            }, 10);
        } else {
            const videoContainer = document.querySelector(".slick-slider.slick-initialized");
            if (!document.fullscreenElement) {
                if (videoContainer.requestFullscreen) {
                    videoContainer.requestFullscreen();
                } else if (videoContainer.mozRequestFullScreen) {
                    videoContainer.mozRequestFullScreen();
                } else if (videoContainer.webkitRequestFullscreen) {
                    videoContainer.webkitRequestFullscreen();
                } else if (videoContainer.msRequestFullscreen) {
                    videoContainer.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    };
    
    // ✅ Function to exit fullscreen on iOS
    const exitFullscreen = () => {
        document.body.classList.remove("ios-fullscreen");
        document.documentElement.classList.remove("ios-fullscreen");
    };
    
    // ✅ Add an "Exit Fullscreen" button for iOS
    const FullscreenExitButton = () => (
        <button className="exit-fullscreen-btn" onClick={exitFullscreen}>Exit Fullscreen</button>
    );
    
    
    
    

    // ✅ Track Fullscreen Mode Changes
    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement || !!document.webkitFullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("mozfullscreenchange", handleFullscreenChange);
        document.addEventListener("MSFullscreenChange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
            document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
        };
    }, []);


    useEffect(() => {
        let lastScrollY = window.scrollY;

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                const videoElement = entry.target;
                const visibilityPercentage = entry.intersectionRatio * 100;
                const currentScrollY = window.scrollY;
                const isScrollingDown = currentScrollY > lastScrollY;
                lastScrollY = currentScrollY;

                //console.log(`🎥 Video: ${videoElement.dataset.id}, Visible: ${visibilityPercentage}%`);

                if (visibilityPercentage > 20 && isScrollingDown) {
                    // ✅ Play video when scrolling **into view** (20% visible)
                    if (videoElement.paused) {
                        //console.log("▶️ Playing video (20% visible)");
                        videoElement.play().catch((error) => console.warn("Autoplay prevented:", error));
                    }
                }
                else if (visibilityPercentage < 20 && !isScrollingDown) {
                    // ✅ Pause video when scrolling **out of view** (20% remaining)
                    if (!videoElement.paused) {
                        //console.log("⏸️ Pausing video (less than 20% visible)");
                        videoElement.pause();
                    }
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: "0px",
            threshold: [0.2, 0.4, 0.6, 0.8, 1], // ✅ Fires when crossing 20%
        });

        videoRefs.current.forEach((video) => {
            if (video) {
                video.dataset.id = video.src; // Assign unique IDs for debugging
                observer.observe(video);
            }
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) observer.unobserve(video);
            });
        };
    }, []);




    useEffect(() => {
        const videoVisibilityHandler = () => {
            videoRefs.current.forEach((video, index) => {
                if (!video) return;

                const rect = video.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // Calculate the percentage of video visible
                const visiblePercentage = ((Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)) / rect.height) * 100;

                //console.log(`🎥 Video ${index}: ${visiblePercentage}% visible`);

                // ✅ Play video when at least 50% visible
                if (visiblePercentage >= 50 && video.paused) {
                    //console.log(`▶️ Playing video: ${video.src}`);
                    video.play().catch(error => console.warn("Autoplay prevented:", error));
                }
                // ✅ Pause video when less than 50% visible
                else if (visiblePercentage < 50 && !video.paused) {
                    //console.log(`⏸️ Pausing video: ${video.src}`);
                    video.pause();
                }
            });
        };

        // ✅ Debounce Scroll Listener (Prevent Unnecessary Re-Renders)
        let scrollTimeout;
        const optimizedScrollHandler = () => {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(videoVisibilityHandler, 100); // 100ms debounce
        };

        window.addEventListener("scroll", optimizedScrollHandler);
        return () => {
            window.removeEventListener("scroll", optimizedScrollHandler);
        };
    }, []);




    //---------------------------------------------------------

    const [showOptions, setShowOptions] = useState(false);
    // Toggle popup visibility
    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const navigate = useNavigate();

    const goToFullscreen = () => {
      //navigate("Components/CarouselFullScreen"); // Redirect to Fullscreen Component
      document.body.style.overflow = "auto";
        navigate(-1);
    };


    return (
        <div className="video-carousel">



            {/* Updated Slider Settings */}
            {/* {console.log("Rendering video carousel with videos:", videos)} */}
            <Slider  {...sliderSettings} ref={sliderRef}>
                {videos.map((video, index) => {
                    //console.log(`Rendering video #${index} with ID: ${video.videoID}`);
                    const isActive = index === activeVideoIndex;


                    return (
                        <div key={video.videoID} className="video-slide" >
                            {/* Updated Video Section */}
                            <div className="video-container">
                                <div className={`video-wrapper ${isFullScreen ? "fullscreen-active" : ""}`}>
                                    <div className="video-top-blur"></div>
                                    {/* <video
                                                className="carousel-video"
                                                poster={video.thumbnail}
                                                src={isActive ? video.videoUrl : undefined}
                                                preload="metadata"
                                                autoPlay={isActive}
                                                loop
                                                muted={isMuted}
                                                controls

                                                ref={(el) => (videoRefs.current[index] = el)} // Correctly assign ref for each video
                                                onClick={() => {
                                                    console.log(`Video ${index} clicked`);
                                                    sliderRef.current.slickGoTo(index);
                                                }}
                                                onTouchStart={handleTouchStart} // ✅ Detect swipe start
                                                onTouchMove={handleTouchMove} // ✅ Detect swipe movement
                                            ></video> */}

                                    {window.innerWidth <= 1024 ? (
                                        // Mobile View (Show only when isActive)
                                        isActive && (
                                            <video
                                                className="carousel-video"
                                                key={video.videoUrl}
                                                src={isActive ? video.videoUrl : ""}
                                                autoPlay={false}
                                                playsInline
                                                loop
                                                muted={isMuted}
                                                ref={(el) => (videoRefs.current[index] = el)} // Assign ref
                                                onClick={(event) => {
                                                    if (index === activeVideoIndex) {
                                                        // ✅ If clicking on the active video, toggle play/pause
                                                        togglePlayPause(event, index);
                                                    } else {
                                                        // ✅ If clicking on another slide, switch to that slide
                                                        sliderRef.current.slickGoTo(index);
                                                        setActiveVideoIndex(index);
                                                        setIsPlaying(true);
                                                    }
                                                }}
                                                onTouchStart={handleTouchStart}
                                                onTouchMove={handleTouchMove}
                                                loading="lazy" // ✅ Lazy loading
                                                preload={isActive ? "auto" : "metadata"}
                                                onLoadedData={(e) => {
                                                    e.target.style.backgroundImage = "none"; // ✅ Remove poster when video loads
                                                }}
                                                onWaiting={(e) => {
                                                    e.target.style.backgroundImage = `url(${video.thumbnail})`; // ✅ Show poster while video is loading
                                                }}
                                                style={{
                                                    backgroundImage: `url(${video.thumbnail})`, // ✅ Force poster for iOS
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                }}
                                            />
                                        )
                                    ) : (
                                        // Desktop View (Always Show Video)
                                        <video
                                            className="carousel-video"
                                            key={video.videoUrl}
                                            src={video.videoUrl}
                                            autoPlay={false}
                                            playsInline
                                            loop
                                            muted={isMuted}
                                            ref={(el) => (videoRefs.current[index] = el)} // Assign ref
                                            onClick={(event) => {
                                                if (index === activeVideoIndex) {
                                                    // ✅ If clicking on the active video, toggle play/pause
                                                    togglePlayPause(event, index);
                                                } else {
                                                    // ✅ If clicking on another video, switch to that slide and play the video
                                                    sliderRef.current.slickGoTo(index);
                                                    setActiveVideoIndex(index);
                                                    setIsPlaying(true);

                                                    // ✅ Ensure newly active video starts playing
                                                    setTimeout(() => {
                                                        videoRefs.current[index]?.play();
                                                    }, 100); // ✅ Small delay ensures video is ready to play
                                                }
                                            }}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            loading="lazy" // ✅ Lazy loading
                                            preload="auto"
                                            onLoadedData={(e) => {
                                                e.target.style.backgroundImage = "none"; // ✅ Remove poster when video loads
                                            }}
                                            onWaiting={(e) => {
                                                e.target.style.backgroundImage = `url(${video.thumbnail})`; // ✅ Show poster while video is loading
                                            }}
                                            style={{
                                                backgroundImage: `url(${video.thumbnail})`, // ✅ Force poster for iOS
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                            }}
                                        />
                                        
                                    )}
                                        <div className="saro-video-overlay"></div>
                                    {/* Show Image Placeholder Only for Mobile When isActive is False */}
                                    {window.innerWidth <= 1024 && !isActive && (
                                        <img
                                            className="carousel-image"
                                            src={video.thumbnail}
                                            alt={video.title}
                                            onClick={() => sliderRef.current.slickGoTo(index)}
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                                cursor: "pointer",
                                            }}
                                        />
                                    )}


                                    {/* ✅ Show Play Icon Only When Video is Paused */}
                                    {!isPlaying && activeVideoIndex === index && (
                                        <div className="play-overlay">
                                            <FontAwesomeIcon icon={faPlay} className="play-icon" />
                                        </div>
                                    )}

                                    <div className="video-title-container">

                                        <FontAwesomeIcon
                                            icon={faEllipsisH}
                                            className="saro-icon menu-icon"
                                            title="More Options"
                                            onClick={toggleOptions}
                                        />
                                        {showOptions && isActive && (
                                            <div className="popup-menu">
                                                <div className="popup-inner-menu">
                                                    {/* Share Button */}
                                                    <button
                                                        className="popup-button b1"
                                                        onClick={async () => {
                                                            try {
                                                                // Copy the link to clipboard
                                                                await navigator.clipboard.writeText(video.productsUrls[0]);
                                                                toggleOptions();

                                                            } catch (error) {
                                                                console.error("Failed to copy link:", error);
                                                            }
                                                        }}
                                                    >
                                                        Copy link
                                                    </button>


                                                    {/* Cancel Button */}
                                                    <button className="popup-button b2" onClick={toggleOptions}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>


                                        )}

                                        <p className="video-title">{video.title}</p>
                                        <div
                                            className="title-icons">
                                            <FontAwesomeIcon
                                                icon={isMuted ? faVolumeMute : faVolumeUp} // Shared mute state
                                                className={`icon speaker-icon ${isMuted ? "muted" : "unmuted"}`}
                                                title={isMuted ? "Unmute All Videos" : "Mute All Videos"}
                                                onClick={toggleMuteUnmute} // Toggle mute state for all videos
                                            />
                                            <FontAwesomeIcon
                                                icon={isFullScreen ? faCompress : faExpand}  // Change icon based on fullscreen state
                                                className="saro-icon fullscreen-icon"
                                                // title={isFullScreen ? "Exit Fullscreen" : "Fullscreen"}
                                              //  onClick={() => handleFullscreen(index)} // ✅ Pass the clicked video index
                                              onClick={goToFullscreen} 
                                            />
                                        </div>
                                    </div>

                                    {/* Updated Product Carousel Section */}
                                    {products[video.videoID] && products[video.videoID].length > 0 && (

                                        <div className="product-card-carousel">

                                            <Slider
                                                dots={false}
                                                infinite={false}
                                                speed={500}
                                                slidesToShow={1} // Show one product at a time
                                                slidesToScroll={1}
                                            >
                                                {products[video.videoID].map(
                                                    (product, productIndex) => {
                                                        //console.log('Video URL : ', video.productsUrls[productIndex])
                                                        return (
                                                            <div
                                                                key={productIndex}
                                                                className="saro-product-card"
                                                            >
                                                                {selectedProduct?.id !== product.id && (
                                                                    <div className="product-card-content">
                                                                        {/* Header section with image, title, and price */}
                                                                        <div className="product-header"
                                                                            onClick={() => handleShopNowClick(product, video.videoID)}
                                                                        >
                                                                            {/* ✅ Uses video thumbnail if product image is missing */}
                                                                            <img
                                                                                className="product-image"
                                                                                src={
                                                                                    product.image && Array.isArray(product.image)
                                                                                        ? product.image.length > 0
                                                                                            ? product.image[0] // ✅ Use first image if array is not empty
                                                                                            : placeholderImage // ✅ Fallback if array is empty
                                                                                        : product.image // ✅ Use direct image URL if it's not an array
                                                                                            ? product.image
                                                                                            : placeholderImage // ✅ Fallback if no image is available
                                                                                }
                                                                                                                                                                alt={product.title || "Product image not available"}
                                                                                onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage; }} // ✅ Handles broken images


                                                                            />




                                                                            <div className="product-info">
                                                                                <span className="product-title">{product.title}</span>

                                                                                <span className="product-price">
    {product.variants?.[0]?.price.amount} {product.variants?.[0]?.price.currencyCode}
</span>


                                                                            </div>
                                                                        </div>

                                                                        {/* Shop Now Button */}
                                                                        <button className="shop-now-modal-button" onClick={() => handleShopNowClick(product, video.videoID)}>
                                                                            Shop Now &gt;
                                                                        </button>

                                                                    </div>



                                                                )}
                                                                {selectedProduct?.id === product.id && (
                                                                    <div className="product-modal">
                                                                        <div className="blur-overlay"></div>
                                                                        <div className="modal-content">
                                                                            <button className="close-button" onClick={closeModal}>
                                                                                &times;
                                                                            </button>
                                                                            <div className="scrollable-content">
                                                                                <Slider dots={false} infinite={false} speed={500} slidesToShow={1} slidesToScroll={1} nextArrow={<NextArrow />}
                                                                                    prevArrow={<PrevArrow />}>
                                                                                    {(Array.isArray(selectedProduct.image) ? selectedProduct.image : [selectedProduct.image]).map((imgSrc, imgIndex) => (
                                                                                        <div key={imgIndex}>
                                                                                            <img
                                                                                                className="modal-product-image"
                                                                                                src={imgSrc || placeholderImage}
                                                                                                alt={selectedProduct.title || "Product image not available"}
                                                                                                onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage; }}
                                                                                            />
                                                                                        </div>
                                                                                    ))}
                                                                                </Slider>
                                                                                <h2 className="modal-product-title">{selectedProduct.title}</h2>


                                                                                {/* {selectedProduct.variants.length > 0 ? (
                                                                                            <div className="variant-selector">
                                                                                                {selectedProduct.variants.length > 0 && <h3>Select Variant:</h3>}

                                                                                                ✅ Loop through all unique variant types dynamically 
                                                                                                {[...new Set(selectedProduct.variants.flatMap((v) => v.options.map((o) => o.name)))]
                                                                                                    .map((variantType) => (
                                                                                                        <div className="variant-dropdown" key={variantType}>
                                                                                                            <label>{variantType}:</label>
                                                                                                            <select
                                                                                                                value={selectedProduct.selectedOptions?.[variantType] || ""}
                                                                                                                onChange={(e) => {
                                                                                                                    const newOptionValue = e.target.value;

                                                                                                                    ✅ Find all variants that match the currently selected options
                                                                                                                    let updatedOptions = { ...selectedProduct.selectedOptions, [variantType]: newOptionValue };

                                                                                                                    ✅ Only remove invalid options, keeping valid selections
                                                                                                                    Object.keys(updatedOptions).forEach((key) => {
                                                                                                                        if (key !== variantType) {
                                                                                                                            const isValid = selectedProduct.variants.some((variant) =>
                                                                                                                                variant.options.some((opt) => opt.name === key && opt.value === updatedOptions[key])
                                                                                                                            );
                                                                                                                            if (!isValid) {
                                                                                                                                delete updatedOptions[key]; 
                                                                                                                                ✅ Remove only invalid options
                                                                                                                            }
                                                                                                                        }
                                                                                                                    });

                                                                                                                    ✅ Find the exact matching variant
                                                                                                                    const matchedVariant = selectedProduct.variants.find((variant) =>
                                                                                                                        variant.options.every((opt) => updatedOptions[opt.name] === opt.value)
                                                                                                                    );

                                                                                                                    setSelectedProduct({
                                                                                                                        ...selectedProduct,
                                                                                                                        selectedOptions: updatedOptions,
                                                                                                                        selectedVariant: matchedVariant?.id || null,
                                                                                                                    });
                                                                                                                }}
                                                                                                            >
                                                                                                                <option value="">Select {variantType}</option>
                                                                                                                {[...new Set(selectedProduct.variants.map((v) => v.options.find((o) => o.name === variantType)?.value))].map(
                                                                                                                    (optionValue) => {
                                                                                                                        ✅ Make sure the option remains selectable if a valid combination exists
                                                                                                                        const isAvailable = selectedProduct.variants.some((v) =>
                                                                                                                            v.options.some((o) => o.name === variantType && o.value === optionValue) &&
                                                                                                                            Object.entries(selectedProduct.selectedOptions || {}).every(([key, value]) =>
                                                                                                                                key === variantType || v.options.some((o) => o.name === key && o.value === value)
                                                                                                                            )
                                                                                                                        );

                                                                                                                        return (
                                                                                                                            <option key={optionValue} value={optionValue} disabled={!isAvailable}>
                                                                                                                                {optionValue} {isAvailable ? "" : "(Unavailable)"}
                                                                                                                            </option>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <p>No variants available</p>
                                                                                        )} */}



<p className="modal-product-price">
    {selectedProduct.variants.find((variant) => variant.id === selectedProduct.selectedVariant)?.price?.amount 
        || selectedProduct.price?.amount || "N/A"} 
    {" "}
    {selectedProduct.variants.find((variant) => variant.id === selectedProduct.selectedVariant)?.price?.currencyCode 
        || selectedProduct.price?.currencyCode || ""}
</p>


                                                                                <h2 className="modal-product-description-heading">Description</h2>
                                                                                <p className="modal-product-description">
                                                                                    {selectedProduct.description || "No description available."}
                                                                                </p>
                                                                            </div>

                                                                            {cart.some((cartItem) => cartItem.id === product.id) ? (
                                                                                <div className="cart-actions">
                                                                                    <button
                                                                                        className="checkout-btn"
                                                                                        onClick={() => handleCheckout(selectedProduct.videoId)}
                                                                                    >
                                                                                        Checkout
                                                                                    </button>


                                                                                    <button
                                                                                        className="quantity-btn"
                                                                                        onClick={() => handleRemoveFromCart(product)}
                                                                                        disabled={loadingProduct === product.id}
                                                                                    >
                                                                                        {loadingProduct === product.id ? (
                                                                                            <span className="button-loader"></span> // ✅ Button-only loader
                                                                                        ) : (
                                                                                            <FontAwesomeIcon icon={faMinusCircle} />
                                                                                        )}
                                                                                    </button>

                                                                                    <span className="cart-quantity">
                                                                                        {cart.find((item) => item.id === product.id)?.quantity || 1}
                                                                                    </span>

                                                                                    <button
                                                                                        className="quantity-btn"
                                                                                        onClick={() => handleAddToCart(product)}
                                                                                        disabled={loadingProduct === product.id}
                                                                                    >
                                                                                        {loadingProduct === product.id ? (
                                                                                            <span className="button-loader"></span> // ✅ Button-only loader
                                                                                        ) : (
                                                                                            <FontAwesomeIcon icon={faPlusCircle} />
                                                                                        )}
                                                                                    </button>
                                                                                </div>

                                                                            ) : (
                                                                                <button
                                                                                    className="shop-now-modal-button"
                                                                                     onClick={() => handleAddToCart(selectedProduct)}
                                                                                    //onClick={() => (window.location.href = `${video.productsUrls[productIndex]}`)}
                                                                                    disabled={loadingProduct === selectedProduct.id}
                                                                                >
                                                                                    {loadingProduct === selectedProduct.id ? (
                                                                                        <span className="button-loader"></span>
                                                                                    ) : (
                                                                                        "Shop Now"
                                                                                    )}
                                                                                </button>

                                                                            )}


                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </Slider>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );


};

export default Videos;
